import React from 'react'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Banner from './banner'
import Layout from './layout'
import Link from './link'
import Meta from './meta'

const GlobalComponents = {
  Banner,
  Link,
}

const PageView = ({ location, data: { mdx } }) => {
  const { body, mdxAST, slug, frontmatter } = mdx
  const { title, author, description } = frontmatter
  const hasMdxBody = mdxAST?.children.length > 1
  console.log('location:', location)
  return (
    <Layout author={author} location={location}>
      <Meta title={title} author={author} description={description} />
      <a id="top"></a>
      <section className="main style1">
        <header className="small">
          {hasMdxBody && (
            <MDXProvider components={GlobalComponents}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
          )}
        </header>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageViewQuery($id: String) {
    mdx(id: { eq: $id }) {
      slug
      body
      mdxAST
      frontmatter {
        author
        description
        title
      }
    }
  }
`

export default PageView
